<template>
    <div>
        <modal :name="modal_name" class="final-modal order-type-success" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header" style="background-color: #fff !important;">
                    <span v-if="order_type == 'Online' || order_type == 'online'" class="v-modal-dialog-title v-modal-title text-dark" style="color: #303031 !important; margin: 0 !important;" >Online | Self Pick Up</span>
                    <span v-else-if="order_type == 'Take Away' || order_type == 'take away'" class="v-modal-dialog-title v-modal-title text-dark" style="color: #303031 !important; margin: 0 !important;" >Take Away</span>
                    <span v-else-if="order_type == 'Deliveries' || order_type == 'deliveries'" class="v-modal-dialog-title v-modal-title text-dark" style="color: #303031 !important; margin: 0 !important;" >Deliveries</span>
                    <span @click="hideSuccessPopup()">
                        <i class="icon icon-close pointer text-secondary"></i>
                    </span>
                </div>
                <div class="v-modal-body pr-1" style="max-height: 330px;overflow-x: hidden;overflow-y: auto;background-color: #fff !important;">
                    <div class="v-modal-layout" style="padding-right: 10px;">
                        <div class="text-center" v-if="order_type == 'Online' || order_type == 'online'">
                            <img src="https://cdn.digicollect.com/cdn/posv2/order_type/shopping-cart.svg" />
                            <div class="fs-14 mt-3" style="color: #303031">
                                Order type changed to 
                                <br>“Online” successfully!
                            </div>
                        </div>
                        <div class="text-center" v-else-if="order_type == 'Take Away' || order_type == 'take away'">
                            <img src="https://cdn.digicollect.com/cdn/posv2/order_type/takeaway.svg" />
                            <div class="fs-14 mt-3" style="color: #303031">
                                Order type changed to 
                                <br>“Take Away” successfully!
                            </div>
                        </div>
                        <div class="text-center" v-else-if="order_type == 'Deliveries' || order_type == 'deliveries'">
                            <avatar
                                class="order-avatar order-type-success pt-6"
                                v-if="delivery_image == ''"
                                :username="delivery_name.replace(/ .*/,'')"
                                :src="delivery_image"
                                :size="70"
                                :rounded="false"
                                color="#00448b"
                                background-color="#00448b">
                            </avatar>
                            <img :src="delivery_image" class="deliveryImage" :alt="delivery_name" v-else>
                            <div class="fs-14 mt-3" style="color: #303031">
                                Delivery Order type selected to  
                                “{{delivery_name}}” successfully!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import Avatar from 'vue-avatar';
export default {
    mixins: [],
    props:['modal_name','order_type', 'delivery_image','delivery_name'],
    data(){
        return{

        }
    },
    components:{
        Avatar
    },
    methods:{
        hideSuccessPopup() {
            this.$emit('hideSuccessPopup');
        }
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
    },
    created(){
    },
    mounted(){
        console.log(this.order_type)
    }
}
</script>
<style scoped>
</style>